@import url("https://unpkg.com/css.gg@2.0.0/icons/css/spinner-alt.css");

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 5px;
}

input[type="color"] {
  padding: 0 !important;
}

.active {
  font-weight: bold;
}

body::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(175, 175, 175);
  border-radius: 20px;
}
